body {
  background-color: white;
  margin: 0;
  overflow-x: hidden;
  -ms-overflow-style: none;
}

html {
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

/* Images */

.logoIMG {
  width: 45px;
  height: auto;
  cursor: pointer;
}

.logometnaamIMG {
  width: 700px;
  height: auto;
  margin-bottom: 20px;
}

.homeIMG {
  margin-top: 20px;
  width: auto;
  height: 550px;
}